// IMPORTS
// --------------------------------

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i&display=swap');

// Mixins
@import "mixins";

// Variables
@import "variables";


// STYLES
// --------------------------------
$menu-width: 260px;

*, *:before, *:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    position: relative;
    right: 0;
    transition: .5s ease;

    &.menu-open {
        @include media-breakpoint-down(md) {
            right: $menu-width;
        }
    }
}


// Buttons/Inputs
// --------------------------------

.btn-icon {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $black;

    &:hover, &:active, &:focus {
        text-decoration: underline;
    }

    &__icon {
        width: 28px;
        height: auto;
    }

    &__label {
        margin-left: 10px;

        @include media-breakpoint-down(sm) {
            @include sr-only;
        }
    }
}

.btn-plain {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    font-family: $font-family-base;
    font-size: 16px;
    cursor: pointer;
    transition: .3s ease;

    &:hover, &:active, &:focus {
        border-color: $gray-700;
    }

    &--light {
        color: $gray-600;
    }

    &--caret {
        display: inline-flex;
        align-items: center;

        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: $gray-900 transparent transparent transparent;
            margin-left: 5px;
        }
    }
}

.btn-prim {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    border-radius: 4px;
    transition: .3s ease;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }

    &--light-green {
        background-color: $white;
        color: $green;

        &:hover, &:active, &:focus {
            background-color: $green;
            color: $white;
        }
    }

    &--purple {
        background-color: $purple;
        color: $white;

        &:hover, &:active, &:focus {
            background-color: darken($purple, 10%);
            color: $white;
        }
    }

    &--blue {
        background-color: $blue;
        color: $white;

        &:hover, &:active, &:focus {
            background-color: darken($blue, 10%);
            color: $white;
        }
    }
}

.btn-more {
    font-size: 12px;
    text-decoration: none;

    &:hover, &:active, &:focus {
        .btn-more__icon {
            transform: translateX(5px);
        }
    }

    &__icon {
        display: inline-block;
        font-style: normal;
        transition: .2s ease;
    }
}

.btn-show-sub {
    position: absolute;
    top: 22px;
    right: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &__icon {
        &:before, &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 20px;
            height: 2px;
            background-color: $black;
            transition: .3s ease;
        }

        &:after {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    &__label {
        @include sr-only;
    }
}

.input-select {
    width: 100%;
    height: 40px;
    min-width: 170px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    background-color: $white;
    border: none;
    border-radius: 3px;
    font-size: 14px;
}


// Header
// --------------------------------

.header {
    @include font-base;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: $container-width;
        margin-left: auto;
        margin-right: auto;
    }

    &__menu {
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    &__btn {
        padding-left: 10px;
        padding-right: 10px;

        @include media-breakpoint-up(md) {
            padding-left: 15px;
            padding-right: 15px;
        }

        &--hidden-lg {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

.logo {
    flex-grow: 1;

    img {
        width: 120px;
        height: auto;

        @include media-breakpoint-up(sm) {
            width: 160px;
        }
    }
}

.main-menu {
    display: block;
    list-style: none;
    padding-left: 0;
    margin: 0;

    @include media-breakpoint-down(md) {
        position: fixed;
        z-index: 1000;
        top: 0;
        bottom: 0;
        right: -$menu-width;
        width: 100%;
        max-width: $menu-width;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: $white;
        box-shadow: 0 0 2px fade-out($black, .8);
        overflow: auto;
        transition: .5s ease;
        
        .menu-open & {
            right: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    &__item {
        @include media-breakpoint-up(lg) {
            padding-left: 2.5px;
            padding-right: 2.5px;
        }

        &--has-submenu {
            position: relative;

            @include media-breakpoint-up(lg) {
                &:hover, &:active, &:focus {
                    .main-menu__link {
                        background-color: $black;
                        color: $white;
                    }

                    .main-menu__submenu {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .main-menu__link {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .btn-show-sub {
                display: block;
                
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        &--submenu-active {
            .main-menu__submenu {
                display: block;
            }
    
            .btn-show-sub__icon:after {
                transform: translate(-50%, -50%);
            }
        }
    }

    &__link {
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12.5px;
        padding-right: 12.5px;
        margin-right: 30px;
        text-decoration: none;
        color: $black;
        border-radius: 5px;
        transition: .2s ease;

        @include media-breakpoint-up(lg) {
            display: block;
            margin-right: 0;
        }

        &:hover, &:active, &:focus {
            background-color: $black;
            color: $white;
        }
    }

    &__show-sub {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__submenu {
        display: none;
        background-color: $black;

        @include media-breakpoint-up(lg) {
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 2.5px;
            display: block;
            min-width: 350px;
            border-radius: 4px;
            border-top-left-radius: 0;
            opacity: 0;
            visibility: hidden;
        }
    }
}

.submenu {
    list-style: none;
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 14px;
    line-height: 1.3;
    transition:  .3s ease;

    @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
    }

    &__item {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
    }

    &__link {
        color: $white;
        text-decoration: none;

        &:hover, &:active, &:focus {
            color: $white;
            text-decoration: underline;
        }
    }
}

.btn-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    background-color: transparent;
    border: none;
    color: inherit;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    transition: .3s ease;

    &:focus {
        outline: none;
    }

    &__label {
        @include sr-only;
    }

    &__bars {
        display: block;
        position: relative;
        width: 30px;
        height: 4px;
        background-color: $black;
        transition: .3s;

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            transition: .3s;
        }

        &:before {
            transform: translate(0, -12px);
        }

        &:after {
            transform: translate(0, 12px);
        }
    }

    .menu-open & {
        .btn-menu__bars {
            background-color: transparent;

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}


// General
// --------------------------------

.main {
    display: block;
    width: 100%;
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    @include font-base;

    h1, h2, h3, h4, h5, h6 {
        font-family: $font-family-base;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        margin-top: 0;
    }

    p {
        margin-top: 0;
    }
}

.search {
    background-color: $blue-light;
    background-image: linear-gradient(to bottom, $blue-light, darken($blue-light, 3%));
    border-radius: 4px;
    overflow: hidden;
    
    &__inner {
        display: flex;
        flex-direction: column;
        padding: 30px;

        @include media-breakpoint-up(sm) {
            align-items: center;
            padding-top: 32.5px;
            padding-bottom: 40px;
            padding-left: 40px;
            padding-right: 40px;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__field-container {
        order: -1;
        margin-bottom: 30px;

        @include media-breakpoint-up(sm) {
            order: 0;
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 30px;
            margin-bottom: 0;
        }
    }

    &__title {
        margin-bottom: 15px;
        font-size: 24px;
        
        @include media-breakpoint-up(md) {
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    &__subtitle {
        margin-bottom: 10px;
        font-size: 18px;
        
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__input {
        display: block;
        width: 400px;
        max-width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        padding-right: 15px;
        font-family: $font-family-base;
        font-size: 14px;
        line-height: 1.5;
        color: inherit;
        background-color: #fff;
        border: 2px solid $gray-700;
        border-radius: 0;
        outline: 1px solid $gray-700;
        outline-offset: 2px;
        transition: .15s ease-in-out;

        &:focus {
            border-color: #b1449f;
        }

        &::placeholder {
            color: $gray-600;
        }
    }

    &__buttons {
        @include media-breakpoint-down(xs) {
            display: flex;
            justify-content: space-around;
        }
    }

    &__advanced {
        display: none;
        padding-top: 12.5px;
        padding-bottom: 12.5px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: darken($blue-light, 3%);

        @include media-breakpoint-up(md) {
            align-items: center;
            justify-content: center;
        }
    }

    &__filter {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        padding-left: 7.5px;
        padding-right: 7.5px;
        font-size: 12px;
    }

    &--home {
        padding-bottom: 40px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        + .home-section {
            margin-top: -40px;
        }
    }

    &--advanced-active {
        .search__advanced {
            display: block;

            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        .search__show-advanced {
            border-color: $black;
        }
    }
}

.search-list {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
    
    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-bottom: 32.5px;
    }

    &__item {
        position: relative;
        border-bottom: 1px solid $gray-400;
        transition: .3s ease;
        
        @include media-breakpoint-up(sm) {
            flex-grow: 1;
            flex-basis: 200px;
            max-width: 200px;
            border-top: none;
            border-bottom: none;
            border-left: 1px solid $gray-400;
            border-right: 1px solid $gray-400;
            margin-top: 7.5px;
            margin-bottom: 7.5px;
            margin-left: -.5px;
            margin-right: -.5px;
        }

        @include media-breakpoint-up(xl) {
            border-left: none;
            margin-left: 0;
            margin-right: 0;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $black;
            opacity: 0;
            transition: .3s ease;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        &:first-child {
            border-top: 1px solid $gray-400;

            @include media-breakpoint-up(sm) {
                border-top: none;
            }
        }

        &:last-child {
            @include media-breakpoint-up(xl) {
                border-right: none;
            }
        }

        &:hover, &--active {
            @include media-breakpoint-down(xs) {
                border-bottom-color: $black;

                &:before {
                    opacity: 1;
                }
            }

            .search-list__link {
                opacity: 1;
            }
        }
    }

    &__link {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 10px;
        color: $black;
        text-decoration: none;
        opacity: .5;
        transition: .3s ease;

        @include media-breakpoint-up(sm) {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
        }

        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }

    &__title {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
    }
}

.content-tabs {
    position: relative;
    overflow: hidden;
}

.content-tab {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;

    &--active {
        position: relative;
        z-index: 1;
        opacity: 1;
        visibility: visible;
    }

}


// Home
// --------------------------------

.home-section {
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    &--mt-0 {
        margin-top: 0 !important;
    }

    &--mb-0 {
        margin-bottom: 0 !important;
    }

    &.featured-pages {
        margin-top: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
}

.banner {
    display: flex;
    align-items: flex-end;
    min-height: 400px;
    padding: 20px;
    background-color: $gray-100;
    background-size: cover;
    background-position: center center;
    text-decoration: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include media-breakpoint-up(md) {
        min-height: 500px;
        padding: 40px;
    }

    &__content {
        width: 100%;
        max-width: 350px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
        background-color: $gray-900;
        color: $white;
        font-size: 12px;
        border-radius: 4px;

        @include media-breakpoint-up(md) {
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 40px;
            padding-right: 40px;
        }

        p {
            margin-top: 0;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        margin-bottom: 15px;
        font-size: 30px;
    }

    &__label {
        margin-bottom: 0 !important;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.content-links {
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    &__item {
        flex-grow: 1;
        display: flex;
        min-height: 340px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        &--lg {
            @include media-breakpoint-up(lg) {
                flex: 0 0 65%;
                max-width: 65%;
            }

            .content-links__content {
                position: relative;
                padding-top: 50px;
                padding-bottom: 50px;
                padding-left: 40px;
                padding-right: 40px;

                @include media-breakpoint-up(md) {
                    padding-top: 70px;
                    padding-bottom: 70px;
                    padding-left: 60px;
                    padding-right: 60px;
                }

                &:before, &:after {
                    content: "";
                    position: absolute;
                    width: 26px;
                    height: 38px;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                    pointer-events: none;
                }

                &:before {
                    top: 20px;
                    right: 20px;
                    transform: rotate(180deg);

                    @include media-breakpoint-up(md) {
                        top: 30px;
                        right: 30px;
                    }
                }

                &:after {
                    bottom: 20px;
                    left: 20px;

                    @include media-breakpoint-up(md) {
                        bottom: 30px;
                        left: 30px;
                    }
                }
            }
        }

        &--sm {
            @include media-breakpoint-up(md) {
                max-width: 50%;
            }

            .content-links__content {
                max-width: 250px;
            }
        }

        &--theme-red {
            .content-links__inner {
                background-color: $blue-pale;
            }

            .content-links__content {
                &:before, &:after {
                    background-image: url(../images/content-shape-red.svg);
                }
            }

            .content-links__links {
                background-color: $red;
                color: $white;

                a {
                    color: $white;
                }
            }
        }

        &--theme-green {
            .content-links__inner {
                background-color: $green-light;
                color: $white;
            }
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-color: $gray-200;
        border-radius: 4px;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            flex-wrap: nowrap;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px;
        font-size: 12px;
        line-height: 1.7;
    }

    &__links {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 25px;
        background-color: $gray-400;

        @include media-breakpoint-up(sm) {
            flex: 0 0 35%;
            max-width: 35%;
        }
    }

    &__title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    &__btn {
        margin-top: auto;
    }
}

.links-list {
    list-style: none;
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 0;
    font-size: 12px;

    li {
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

    &__title {
        margin-top: auto !important;
        margin-bottom: 15px;
        font-size: 16px;
    }

    &__link {
        text-decoration: none;

        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }

    &__more {
        margin-top: auto;
        margin-left: auto;
    }
}

.featured-pages {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.featured-page {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;

    @include media-breakpoint-up(sm) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 25%;
        max-width: 25%;
    }

    &:hover, &:active, &:focus {
        text-decoration: none;

        .featured-page__image {
            transform: scale(1.1);
        }
    }

    &__image-container {
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 10px;
    }

    &__image {
        padding-bottom: 60%;
        background-color: $gray-200;
        background-size: cover;
        background-position: center center;
        transform: scale(1.01);
        transition: .3s ease;
    }

    &__title {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        margin-bottom: 0;
        color: $black;
    }
}

.section-title {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 30px;
    text-align: center;

    @include media-breakpoint-up(md) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 2px;
        background-color: $black;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
    }
}

.image-content {
    padding-left: 25px;
    padding-right: 25px;

    @include media-breakpoint-up(md) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &__inner {
        background-color: $gray-100;
        border-radius: 4px;

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    &__image-container {
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(sm) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            max-width: 50%;
            display: flex;
            padding-right: 0;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center center;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            padding-bottom: 85%;
        }
    }

    &__image-filter {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;

        &:before, &:after {
            content: "";
            position: absolute;
            width: 26px;
            height: 38px;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            pointer-events: none;

            @include media-breakpoint-up(sm) {
                width: 49px;
                height: 70px;
            }
        }

        &:before {
            top: 20px;
            right: 20px;
            transform: rotate(180deg);

            @include media-breakpoint-up(lg) {
                top: 50px;
                right: 50px;
            }
        }

        &:after {
            bottom: 20px;
            left: 20px;

            @include media-breakpoint-up(lg) {
                bottom: 50px;
                left: 50px;
            }
        }
    }

    &__content {
        padding: 30px;
        font-size: 12px;
        line-height: 1.7;

        @include media-breakpoint-up(sm) {
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-up(md) {
            align-self: center;
            flex: 0 0 50%;
        }

        p {
            margin-bottom: 15px;
        }
    }

    &__title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    &__btn {
        min-width: 170px;
        margin-top: 15px;
    }

    &--lg {
        padding-top: 15px;
        padding-left: 40px;

        @include media-breakpoint-up(sm) {
            padding-top: 30px;
            padding-left: 55px;
        }

        @include media-breakpoint-up(md) {
            padding-left: 70px;
            padding-top: 50px;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 90px;
            padding-top: 80px;
        }

        .image-content__image-container {
            padding-left: 0;
            padding-right: 15px;
            margin-left: -15px;
            margin-bottom: -15px;
            transform: translateY(-15px);

            @include media-breakpoint-up(sm) {
                padding-right: 30px;
                margin-bottom: -30px;
                margin-left: -30px;
                transform: translateY(-30px);
            }
            
            @include media-breakpoint-up(md) {
                flex-basis: 65%;
                max-width: 65%;
                padding-right: 0;
                margin-top: -50px;
                margin-bottom: 30px;
                transform: translateY(0);
            }

            @include media-breakpoint-up(lg) {
                margin-top: -80px;
                margin-bottom: 50px;
                margin-left: -50px;
            }
        }

        .image-content__image {
            border-radius: 4px;
        }

        .image-content__content {
            max-width: 320px;

            @include media-breakpoint-up(md) {
                flex-basis: calc(35% + 30px);
            }

            @include media-breakpoint-up(lg) {
                flex-basis: calc(35% + 50px);
            }
        }
    }

    &--theme-orange {
        .image-content__inner {
            background-color: $orange;
            color: $white;
        }
    }

    &--theme-yellow {
        .image-content__inner {
            background-color: $yellow;
            color: $blue;
        }

        .image-content__image-filter {
            background-color: rgba($blue, .5);

            &:before, &:after {
                background-image: url(../images/content-shape.svg);
            }
        }
    }
}


.latest-news {
    padding-left: 25px;
    padding-right: 25px;

    @include media-breakpoint-up(md) {
        padding-left: 70px;
        padding-right: 40px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 90px;
    }

    &__inner {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $blue-light;
        border-radius: 4px;

        @include media-breakpoint-up(lg) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    &__items {
        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -25px;
            margin-right: -25px;
        }

        @include media-breakpoint-up(md) {
            margin-left: -40px;
            margin-right: -35px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -70px;
        }
    }
}

.news-item {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @include media-breakpoint-up(md) {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
    
    @include media-breakpoint-up(lg) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__inner {
        display: block;
        width: 100%;
        padding: 20px;
        font-size: 12px;
        background-color: $white;
        border-radius: 4px;

        @include media-breakpoint-up(lg) {
            padding: 30px;
        }

        .btn-more {
            color: $black;
            margin-left: auto;
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__link {
        color: $black;
        text-decoration: none;
        border: 1px solid transparent;
        transition: .3s ease;

        &:hover, &:active, &:focus {
            color: $black;
            text-decoration: none;
            border-color: $black;
        }
    }

    &__title {
        font-size: 14px;
        margin-bottom: 10px;
    }

    &--featured {
        @include media-breakpoint-only(sm) {
            flex-basis: 100%;
            max-width: 100%;
        }

        .news-item__inner {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 14px;
            border: 1px solid $black;
        }

        .news-item__title {
            font-size: 24px;
        }
    }
}


// Search
// --------------------------------

.results-head {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: $black;
    color: $white;
    border-radius: 4px;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 0;
        margin-right: 0;
    }

    &__title {
        font-size: 20px;
        margin-bottom: 15px;

        @include media-breakpoint-up(lg) {
            padding-left: 10px;
            margin-bottom: 0;
        }
    }

    &__actions {
        @include media-breakpoint-up(sm) {
            display: flex;
            margin-left: -5px;
            margin-right: -5px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: auto;
        }
    }

    &__action {
        padding-top: 5px;
        padding-bottom: 5px;

        @include media-breakpoint-up(sm) {
            flex-grow: 1;
            flex-basis: 0;
            padding-left: 5px;
            padding-right: 5px;
        }

        @include media-breakpoint-up(lg) {
            flex-grow: 0;
            flex-basis: auto;
        }

        &--hidden-lg {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

.filter-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    border: none;
    background-color: $white;
    color: $black;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s ease;

    &:hover, &:active, &:focus {
        background-color: $gray-200;
    }

    &__icon {
        width: 15px;
        height: auto;
        margin-right: 5px;
    }
}

.results-body {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.results-side {
    width: 360px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(md) {
        position: fixed;
        z-index: 999;
        top: 0;
        bottom: 0;
        right: -360px;
        width: 100%;
        max-width: 360px;
        padding-top: 30px;
        background-color: $white;
        box-shadow: 0 0 2px fade-out($black, .8);
        overflow: auto;
        transition: .5s ease;
        
        &--active {
            right: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 360px;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 15px;
        padding: 5px;
        font-size: 30px;
        line-height: 1;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__section {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__title {
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid $black;
    }
}

.active-filters {
    padding-top: 15px;
    padding-bottom: 15px;
}

.active-filter {
    padding-top: 5px;
    padding-bottom: 5px;

    &__inner {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: lighten($blue-light, 4%);
        border-radius: 4px;
    }

    &__content {
        flex-grow: 1;
        font-size: 14px;
    }

    &__title {
        margin-bottom: 3px;
        font-size: 10px;
        color: $gray-600;
        text-transform: uppercase;
    }

    &__dismiss {
        flex-shrink: 0;
        display: block;
        padding-left: 10px;
        padding-right: 10px;
        color: $gray-600;
        font-size: 26px;
        line-height: 1;
        text-decoration: none;
        transition: .3s ease;

        &:hover, &:active, &:focus {
            color: $black;
            text-decoration: none;
        }
    }
}

.results-filter {
    padding-top: 20px;
    padding-bottom: 20px;

    &__title {
        font-size: 14px;
        margin-bottom: 15px;
    }

    &__inner {
        padding: 15px;
        background-color: lighten($blue-light, 4%);
        border-radius: 4px;
    }

    &__buttons {
        text-align: right;
        margin-top: 10px;
    }

    &__btn {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 2px;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        transition: .3s ease;
        cursor: pointer;

        &:hover, &:focus {
            border-color: $black;
        }
    }
}

.filter-item {
    display: flex;
    align-items: center;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    font-size: 12px;

    &__input {
        flex-shrink: 0;
        margin-right: 10px;
    }

    &__checkbox {
        margin: 0;
    }

    &__title {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__count {
        color: $gray-600;
    }
}

.results-main {
    flex-grow: 1;
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.results-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 9px;
    font-size: 14px;

    &__item {
        padding-left: 2.5px;
        padding-right: 2.5px;
    }

    &__link {
        display: block;
        min-width: 24px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 3px;
        padding-right: 3px;
        color: $black;
        text-decoration: none;
        border-radius: 2px;
        text-align: center;
        transition: .3s ease;

        &:hover, &:active, &:focus, &--active {
            background-color: $black;
            color: $white;
        }
    }

    &__spacer {
        display: block;
        width: 24px;
        text-align: center;
    }

    &--mt {
        margin-top: 9px;
        margin-bottom: 0;
    }
}

.search-results {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
}

.search-result {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-300;

    &:last-child {
        border-bottom: none;
    }

    &__input {
        flex-shrink: 0;
        padding-left: 5px;

        @include media-breakpoint-up(sm) {
            padding-left: 10px;
        }
    }

    &__checkbox {
        margin: 0;
    }

    &__count {
        display: inline-block;
        min-width: 30px;
        font-size: 12px;
    }

    &__image {
        flex-shrink: 0;
        width: 70px;
        padding-left: 5px;
        padding-right: 5px;

        @include media-breakpoint-up(sm) {
            width: 110px;
            padding-left: 10px;
            padding-right: 10px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    &__content {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 10px;
        color: $gray-700;

        @include media-breakpoint-up(sm) {
            padding-left: 10px;
            padding-right: 10px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    &__title {
        font-size: 14px;
        margin-bottom: 10px;
        color: $black;
    }
}
