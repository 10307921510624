// Colours
// --------------------------------

$white: #fff;
$gray-100: #f7f7f7;
$gray-200: #eeeeee;
$gray-300: #e6e6e6;
$gray-400: #dadada;
$gray-500: #bdbdbd;
$gray-600: #797979;
$gray-700: #505050;
$gray-800: #313131;
$gray-900: #111111;
$black: #000;

$blue: #0047BA;
$blue-light: #E3F1F9;
$blue-pale: #F1FFFB;
$green: #229276;
$green-light: #0FBF9E;
$purple: #B7217F;
$red: #C20D4C;
$orange: #F89A2C;
$yellow: #FFD100;

$body-color: $black;


// Fonts
// --------------------------------

$font-family-base:  "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base:    16px;
$font-weight-base:  400;
$line-height-base:  1.5;

$headings-font-weight: 500;
$headings-line-height: 1.2;


// Layout
// --------------------------------
$container-width: 1100px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
